import { io } from 'socket.io-client';

let socket;

export const initSocket = (user) => {
    if (!socket) {
        socket = io('wss://taskbotmasters-api.com', {
            query: { userId: user.user_id },
            transports: ['websocket'],
        });

        socket.on('connect', () => {
            console.log('Connected to server with id:', user.user_id);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from server with id:', user.user_id);
        });

        socket.on('error', (error) => {
            console.error('Socket connection error:', error);
        });
    }
    return socket;
};

export const getSocket = () => {
    if (!socket) {
        throw new Error('Socket not initialized. Call initSocket() first.');
    }
    return socket;
};