import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = (data) => fetch({
	url: '/auth/signin',
	method: 'post',
	data: data
})

AuthService.register = (data) => fetch({
	url: '/auth/register',
	method: 'post',
	data: data
})

export default AuthService;