import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch } from 'react-redux';

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (blankLayout) {
			dispatch(onBlankLayout());
		} else {
			dispatch(onBlankLayout(false));
		}
	}, [dispatch, blankLayout]);

	return (
		<Component {...props} />
	)
}

export default AppRoute