import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'taskbotmasters';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `/`; //`${APP_PREFIX_PATH}/dashboards/default`
export const UNAUTHENTICATED_ENTRY = '/login'

export const ADMINISTRATIVE = 'administrativo'
export const COMERCIAL = 'comercial'
export const NEW_MOTORCYCLES = 'motos-novas'
export const WORKSHOP = 'oficina'
export const USED_MOTORCYCLES = 'motos usadas'
export const STORE = 'acessorios'

export const TASK = 'task'
export const QUEUE = 'queue'
export const PENDING = 'pending'
export const DONE = 'done'

export const scrumboard_TASK = 'Fila'
export const scrumboard_QUEUE = 'Tarefas'
export const scrumboard_PENDING = 'Pendente'
export const scrumboard_DONE = 'Concluído'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'pt',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
