import fetch from 'auth/FetchInterceptor';
import dayjs from 'dayjs';
dayjs.locale('eu-gb')

const UserService = {}

UserService.create = (data) => {
	return fetch({
		url: '/account/create',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.users = () => {
	return fetch({
		url: '/account/read',
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	}).then(res => {
		const users = res?.rows.map(user => {
			return {
				id: user?.id,
				name: user?.name,
				email: user?.email,
				img: user?.avatar,
				role: user?.role,
				lastOnline: user?.lastOnline,
				status: user?.active,
				city: user?.city,
				postcode: user?.postcode,
				address: user?.address,
				website: user?.website,
				phoneNumber: user?.phoneNumber,
				birthday: user?.dateOfBirth ? dayjs(user?.dateOfBirth.toLocaleString('en-US', { timeZone: 'Europe/Lisbon' })) : '',
				location: user?.address,
				morada: [],
				color: user?.color,
				personalInfo: {
					location: user?.address,
					title: user?.title,
					birthday: user?.dateOfBirth,
					phoneNumber: user?.phoneNumber,
					facebook: user?.facebook,
					twitter: user?.twitter,
					instagram: user?.instagram,
					site: user?.site
				},
			}
		})
		return users
	}).catch(error => {
		return error
	})
}

// UserService.findByParams = (dados) => {
// 	return fetch({
// 		url: `/account/read?${dados}`,
// 		method: 'GET',
// 		headers: {
// 			'Content-Type': 'application/json'
// 		},
// 	})
// }


UserService.userbyId = (user_id) => {
	return fetch({
		url: `/account/read/?id=${user_id}`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	}).then(res => {
		if (res?.count > 0) {
			return {
				...res.rows[0],
				count: res.count
			}
		} else {
			return {}
		}
	})

}

UserService.userRoles = (user_id) => {
	return fetch({
		url: `/account/read-roles?user_id=${user_id}`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

UserService.roles = () => {
	return fetch({
		url: `/account/read-roles`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

UserService.updateRole = (data) => {
	return fetch({
		url: '/account/update-roles',
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.createRole = (data) => {
	return fetch({
		url: '/account/create-roles',
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.update = (data) => {
	return fetch({
		url: '/account/update',
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

UserService.updateAvatar = (data) => {
	return fetch({
		url: '/account/avatar',
		method: 'PUT',
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data: data
	})
}

UserService.changePassword = (data) => {
	return fetch({
		url: '/account/update-password',
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

export default UserService;