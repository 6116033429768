import fetch from 'auth/FetchInterceptor'

const CompanyService = {}

CompanyService.list = (id) => {
	return fetch({
		url: `/company/find?id=${id}`,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	})
}

CompanyService.create = (data) => {
	return fetch({
		url: `/company`,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}

CompanyService.update = (data) => {
	return fetch({
		url: `/company/update/${data.id}`,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		data: data
	})
}


export default CompanyService;