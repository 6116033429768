import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const unauthorizedCode = [401, 403]

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 60000,
	retry: 3,
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
	}

	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API response interceptor
service.interceptors.response.use((response) => {
	return response.data || null
}, (error) => {
	let notificationParam = {
		message: '',
		description: ''
	}

	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response?.statusCode || error.response?.status)) {
		notificationParam.message = 'Sessão expirada'
		notificationParam.description = 'Por favor, faça login novamente.'
		localStorage.removeItem(AUTH_TOKEN)
		store.dispatch(signOutSuccess())
		return notification.warning(notificationParam)
	}

	if (error.response.status === 400) {
		notificationParam.message = 'Verifique os dados introduzidos'
		notificationParam.description = error.response.data.message
		return notification.warning(notificationParam)
	}


	if (error.response.status === 404) {
		if (error.response.data.erro.includes('Postal Code not found!')) {
			notificationParam.message = 'Código Postal não encontrado'
			notificationParam.description = 'Por favor, introduza um código postal válido ou preencha os campos manualmente.'
			return notification.error(notificationParam)
		} else {
			notificationParam.message = 'Não encontrado'
			notificationParam.description = 'A funcionalidade à qual está a tentar aceder não se encontra disponível.'
			return notification.error(notificationParam)
		}
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Ops! Algo correu mal'
		notificationParam.description = 'Não se preocupe, já estamos a trabalhar para resolver o problema.'
		localStorage.removeItem(AUTH_TOKEN)
		localStorage.removeItem('labels');
		localStorage.removeItem('members');
		localStorage.removeItem('members_details');
		store.dispatch(signOutSuccess())
		window.location.href = '/signin'
		return notification.error(notificationParam)
	}

	if (error.response.status === 508) {
		notificationParam.message = 'Erro interno do servidor (508)'
		notificationParam.description = 'Por favor, tente novamente mais tarde.'
		return notification.error(notificationParam)
	}

	return 
});

export default service